import React from 'react';
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  RequiredKey,
  TextField,
} from '@availity/element';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import BlockUi from 'react-block-ui';
import _get from 'lodash/get';

import { createMailbox } from '@/api';

const ERA_SENDER = 'ERASender';
const RECEIVER = 'Receiver';
const EROOM = 'ERoom';
const MULTIOFFICE = 'MultiOffice';

const templateOptions = [
  { label: 'Receiver', id: RECEIVER },
  { label: 'ERA Sender', id: ERA_SENDER },
  { label: 'ERoom', id: EROOM },
  { label: 'Multiple Office', id: MULTIOFFICE}
] as const;

type FormValues = {
  template: (typeof templateOptions)[number];
  mailbox: string;
};

const initialValues: FormValues = {
  mailbox: '',
  template: templateOptions[0],
};

const createRequest = ({ template, mailbox }: FormValues) => {
  switch (template.id) {
    case RECEIVER: {
      return {
        target: `RECEIVER/CURRENT/${mailbox}`,
        archiveTarget: `RECEIVER/ARCHIVE/${mailbox}`,
        template: template.id,
      };
    }
    case EROOM: {
      return {
        target: `E-ROOMS/${mailbox}`,
        template: template.id,
      };
    }
    case ERA_SENDER: {
      return {
        target: `ERASENDER/CURRENT/${mailbox}`,
        archiveTarget: `ERASENDER/ARCHIVE/${mailbox}`,
        template: template.id,
      };
    }
    case MULTIOFFICE: {
      return {
        target: `MULTI_OFFICE/CURRENT/${mailbox}`,
        archiveTarget: `MULTI_OFFICE/ARCHIVE/${mailbox}`,
        template: template.id,
      };
    }
    default: {
      return {
        target: '',
        template,
        type: '',
      };
    }
  }
};

export const CreateMailbox = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset: resetForm,
  } = useForm<FormValues>({ defaultValues: initialValues });

  const {
    mutate: handleCreateMbx,
    data,
    isLoading,
    isError,
    isSuccess,
    reset: resetMutation,
  } = useMutation(createMailbox);
  let _isError = false;
  if (isError) {
    _isError = isError;
  } else if (Array.isArray(data?.errors) && data.errors.length > 0) {
    _isError = true;
  }

  const _isSuccess = _isError ? false : isSuccess;

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    handleCreateMbx(createRequest(values));
  };

  return (
    <Container>
      <BlockUi blocking={isLoading} tag={Card}>
        <CardHeader title="Create Mailbox" />
        <CardContent>
          <Container>
            {_isSuccess && <Alert severity="success">Mailbox, {data?.target}, created successfully!</Alert>}
            {_isError && (
              <Alert severity="error">
                There was an error creating the mailbox
                <br />
                {_get(data, 'errors[0].message', null)}
              </Alert>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container direction="column" marginBottom={3}>
                <h3>Mailbox Information</h3>
                <RequiredKey />
              </Grid>
              <Grid container direction="column">
                <Grid marginBottom="1.5rem">
                  <Controller
                    control={control}
                    name="template"
                    render={({ field: { onChange, value, onBlur } }) => {
                      return (
                        <Autocomplete
                          disableClearable
                          FieldProps={{
                            label: 'Template',
                            required: true,
                            error: !!errors.template?.message,
                            helperText: errors.template?.message,
                          }}
                          onBlur={onBlur}
                          onChange={(event, value, reason) => {
                            if (reason === 'clear') {
                              onChange(null);
                            }
                            onChange(value);
                          }}
                          options={templateOptions}
                          value={value}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid marginBottom="1.5rem">
                  <TextField
                    {...register('mailbox', {
                      required: 'Mailbox is required.',
                      pattern: { value: /^\w+$/, message: 'Mailbox must only container letters and numbers' },
                    })}
                    label="Mailbox"
                    fullWidth
                    error={!!errors.mailbox?.message}
                    helperText={errors.mailbox?.message || 'Careful! This cannot be changed later.'}
                    required
                  />
                </Grid>
              </Grid>
              <Button
                color="secondary"
                type="reset"
                onClick={() => {
                  resetForm();
                  resetMutation();
                }}
              >
                Clear
              </Button>
              <Button type="submit" color="primary" className="float-right">
                Submit
              </Button>
            </form>
          </Container>
        </CardContent>
      </BlockUi>
    </Container>
  );
};
