import React from 'react';
import Icon from '@availity/icon';

export const SuccessMessage = () => {
  return (
    <h4 className="text-center">
      Success!
      <Icon aria-hidden="true" name="ok-circle" className="text-success ml-1" />
    </h4>
  );
};
