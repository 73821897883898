import React from 'react';
import { Link } from 'react-router-dom';
import ChangePassword, { ChangePasswordForm } from '@availity/change-password';
import { Field } from '@availity/form';
import { useQueryParams } from '@/hooks';
import { ForgotPasswordChangePasswordApi, passwordConditions, updatePasswordSchema } from '@/api';

const onHandleSubmit = ({ result, setError, setSuccess }) => {
  if (Array.isArray(result.errors) && result.errors.length > 0) {
    setError(`An error occurred changing your password: ${result.errors[0].message}`);
    setSuccess(null);
  } else if (!result.updateSuccessful) {
    // This is the only situation that can't be caught by the client. Every other password rule should be handled client side before it ever gets to the server
    if (result.warnings.authenticated === false) {
      setError("An error occurred changing your password: The user's current password is incorrect");
    } else {
      setError('An error occurred changing your password');
    }
    setSuccess(null);
  } else {
    setError(null);
  }
};

export const UpdatePassword = () => {
  const { passwordResetId, username } = useQueryParams();

  const changePasswordApi = new ForgotPasswordChangePasswordApi({}, passwordResetId);

  return (
    <>
      <ChangePassword resource={changePasswordApi} schema={updatePasswordSchema(username)} conditions={passwordConditions(username)}>
        <ChangePasswordForm
          onHandleSubmit={onHandleSubmit}
          header={<h4>Update Password</h4>}
          maxLength={null}
          additionalFields={<Field name="username" id="username" label="Username" disabled />}
          initialValues={{ username }}
          showCurrentPassword={false}
        />
      </ChangePassword>
      <br />
      <Link to="/">Go to Login</Link>
    </>
  );
};

