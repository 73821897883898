import { AvMicroserviceApi } from '@availity/api-axios';

import type { MFTNodes } from '@/types';

export const ExternalApi = new AvMicroserviceApi({
  path: '/api',
  headers: {
    'X-Client-ID': 'test',
  },
});

const FILE = 'file';

export const login = async (request: { username: string; password: string }) => {
  try {
    const response = await ExternalApi.post(request, { name: '/login' });
    return response.data.message === 'Success';
  } catch {
    return false;
  }
};

export type ExpiredResponse = {
  isUserPasswordExpired: boolean;
};

export const getPasswordExpired = async () => {
  const response = await ExternalApi.query<ExpiredResponse>({ name: '/user/password/expired' });
  return response.data.isUserPasswordExpired;
};

export const logout = async () => {
  try {
    const response = await ExternalApi.post({}, { name: '/logout' });
    return response.data.loggedIn === false;
  } catch {
    return false;
  }
};

export type AuthenticatedResponse = {
  authenticated: boolean;
};

export const getIsAuthenticated = async () => {
  try {
    const response = await ExternalApi.query<AuthenticatedResponse>({ name: '/authenticated' });
    return response;
  } catch {
    return { data: { authenticated: false } };
  }
};

/**
 * Fetch folders and files for the given directory
 */
export const externalFetchAllNodes = async (name: string) => {
  const request = {
    node_name: name,
  };

  try {
    // <MFTNodes> allows us to specify what the data property of
    // the axios response will be, e.g. MFTNodes since it would
    // otherwise be any
    const resp = await ExternalApi.post<MFTNodes>(request, {
      name: '/listchildnodes',
      withCredentials: true,
      polling: true,
      pollingMethod: 'POST',
      pollingIntervals: [1e3, 2e3, 5e3, 1e4, 15e3, 2e4, 3e4], // in ms
    });

    return resp.data;
  } catch {
    throw new Error(`Unable to fetch ${name}`);
  }
};

/**
 * Delete the folder or file node with the given name
 */
const deleteNode = async (type: 'file' | 'folder', name: string, customerId: string) => {
  const request = {
    node_type: type,
    node_name: name,
    customer_id: customerId,
  };

  try {
    const response = await ExternalApi.post(request, {
      name: '/delete',
      withCredentials: true,
    });
    return response.data;
  } catch {
    throw new Error(`There was an error deleting node ${name} of type ${type}`);
  }
};

/**
 * Delete the file with the given name
 */
export const externalDeleteFile = async (name: string, customerId: string) => {
  const response = await deleteNode(FILE, name, customerId);
  return response;
};

/**
 * Upload a file with the given name
 */
export const externalUploadFile = async (name: string, path: string, file: File, customerId: string) => {
  const request = new FormData();
  request.append('file_name', name);
  request.append('file_path', path);
  request.append('filetoupload', file);
  request.append('customer_id', customerId);

  try {
    const response = await ExternalApi.post(request, {
      name: '/upload',
      timeout: Number.POSITIVE_INFINITY,
      withCredentials: true,
    });
    return response.data;
  } catch {
    throw new Error(`There was an error uploading the file ${name}`);
  }
};

/**
 * Download a file with the given name
 */
export const externalDownloadFile = async (path: string, customerId: string) => {
  const request = {
    path,
    customer_id: customerId,
  };

  try {
    const response = await ExternalApi.post(request, {
      responseType: 'blob',
      name: '/download',
      withCredentials: true,
    });

    const filename = response.headers['content-disposition']?.split('filename=')[1];

    return { file: response.data, filename, type: response.headers['content-type'] };
  } catch {
    throw new Error(`There was an error downloading the file`);
  }
};

export const externalResetUserPassword = async () => {
  const response = await ExternalApi.put({}, { name: '/user/resetpassword' });

  return response.data;
};

export const requestPasswordResetLink = async (username: string) => {
  const response = await ExternalApi.post({ username }, { name: '/password-reset-link' })
  return response.data;
}

