import React, { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { Alert } from 'reactstrap';

import { FolderItem } from '@/components';
import { useExternalFolders } from '@/hooks';
import type { Folder } from '@/types';

export const ExternalFileSystemTree = ({
  folder,
  isRoot = false,
  selectedFolder,
  setHasFolderError,
  setSelectedFolder,
}: {
  folder: Folder;
  isRoot?: boolean;
  selectedFolder: Folder;
  setHasFolderError: (value: boolean) => void;
  setSelectedFolder: (folder: Folder) => void;
}) => {
  const [isOpen, setIsOpen] = useState(isRoot);

  const {
    data: folders = [],
    isError,
    isFetched,
    isLoading,
  } = useExternalFolders(folder.data, { enabled: !!folder.data && isOpen });

  useEffect(() => {
    setHasFolderError(isError);
  }, [isError, setHasFolderError]);

  const toggleIsOpen = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <BlockUi tag="ul" className={`list-unstyled${isRoot ? ' ml-0' : ' ml-3'}`} blocking={isLoading && isOpen}>
      <FolderItem
        folder={folder}
        isActive={folder.key === selectedFolder.key}
        isOpen={isOpen}
        onClick={setSelectedFolder}
        toggleIsOpen={toggleIsOpen}
      >
        {folders.map((node) => (
          <ExternalFileSystemTree
            key={node.key}
            folder={node}
            selectedFolder={selectedFolder}
            setSelectedFolder={setSelectedFolder}
            setHasFolderError={setHasFolderError}
          />
        ))}
        <Alert color="warning" isOpen={isFetched && isRoot && folders.length === 0}>
          No folders available.
        </Alert>
      </FolderItem>
    </BlockUi>
  );
};
