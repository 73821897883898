import React from 'react';
import Icon from '@availity/icon';
import { Collapse } from 'reactstrap';

import type { Folder } from '@/types';

export const FolderItem = ({
  children,
  folder,
  isActive,
  isOpen,
  onClick,
  toggleIsOpen,
}: {
  children?: React.ReactNode;
  folder: Folder;
  isActive: boolean;
  isOpen: boolean;
  onClick: (folder: Folder) => void;
  toggleIsOpen: () => void;
}) => {
  const handleOnClick = () => {
    // Set folder for file table
    onClick(folder);

    // Don't close folder if were clicking into it
    if (!isActive && isOpen) return;

    toggleIsOpen();
  };

  return (
    <li>
      <Icon
        name={isOpen ? 'angle-down' : 'angle-right'}
        onClick={() => {
          toggleIsOpen();
        }}
      />
      <span
        role="button"
        tabIndex={0}
        onClick={handleOnClick}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleOnClick();
          }
        }}
      >
        <Icon name={isActive ? 'folder-open-empty' : 'folder-empty'} />
        {folder.label}
      </span>
      <Collapse isOpen={isOpen}>{isOpen && children}</Collapse>
    </li>
  );
};
