import React from 'react';
import { Input } from 'reactstrap';
import { useField, useFormikContext } from 'formik';
import { v4 as uuid } from 'uuid';

const FilePicker = ({
  allowedFileTypes,
  innerRef,
  maxFiles,
  maxSize,
  name,
  setLargeFiles,
  setMaxFilesError,
}: {
  allowedFileTypes: string[];
  innerRef: React.Ref<HTMLInputElement>;
  maxFiles: number;
  maxSize: number;
  name: string;
  setLargeFiles: (value: string[]) => void;
  setMaxFilesError: (value: boolean) => void;
}) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField<(File & { id: string })[]>(name);

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const files = event.target.files || [];
    const selectedFiles: (File & { id?: string })[] = [];
    const largeFiles: string[] = [];

    let idx = 0
    for (const v of files){
      selectedFiles[idx] = v;
      selectedFiles[idx].id = uuid();
      idx += 1;
    }

    // Remove large files
    const filtered = selectedFiles.filter((file) => {
      const isLarge = file.size > maxSize;
      // Save the name for the error message
      if (isLarge) {
        largeFiles.push(file.name);
      }
      return !isLarge;
    });

    if (largeFiles.length > 0) {
      setLargeFiles(largeFiles);
    }

    const filesToSet = [...field.value, ...filtered];

    if (filesToSet.length > maxFiles) {
      setMaxFilesError(true);
    }

    setFieldValue(name, filesToSet.slice(0, 5), true);
  };

  const allowedTypes = allowedFileTypes.length > 0 ? allowedFileTypes.join(',') : undefined;

  return (
    <Input
      value=""
      type="file"
      id={name}
      // TODO: is this doing anything?
      accept={allowedTypes}
      onChange={handleOnChange}
      innerRef={innerRef}
      multiple
    />
  );
};

export default FilePicker;
