import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import BlockUi from 'react-block-ui';
import Icon from '@availity/icon';
import { Form } from '@availity/form';
import { useField, useFormikContext } from 'formik';

import { uploadFile, externalUploadFile } from '@/api';
import { useAppContext } from '@/context';
import type { Folder } from '@/types';

import FilePickerBtn from './FilePickerBtn';
import FileList from './FileList';
import { SuccessMessage } from './SuccessMessage';

type UploadRequest = {
  files: (File & { id: string })[];
  path: string;
  customerId: string;
};

type UploadResponse = {
  message: string;
  file_permission: string;
};

const MAX_FILE_SIZE = 1048576 * 150; // 150MB
const MAX_FILES = 5;
const FILE_INPUT_NAME = 'files';
const ALLOWED_FILE_TYPES: string[] = ['txt'];

type FormValues = { [FILE_INPUT_NAME]: (File & { id: string })[] };
const initialValues: FormValues = { [FILE_INPUT_NAME]: [] };

const FileUpload = () => {
  const [maxFilesError, setMaxFilesError] = useState(false);
  const [largeFiles, setLargeFiles] = useState<string[]>([]);

  const [field] = useField<(File & { id: string })[]>(FILE_INPUT_NAME);
  const { setFieldValue } = useFormikContext();

  const handleRemoveFile = (id: string) => {
    // Filter old value
    const newValue = field.value.filter((file) => file.id !== id);

    // Update form with new value
    setFieldValue(FILE_INPUT_NAME, newValue, true);
  };

  return (
    <>
      <Alert color="danger" isOpen={!!maxFilesError} toggle={() => setMaxFilesError(false)}>
        You may only select {MAX_FILES} files at a time
      </Alert>
      <Alert color="danger" isOpen={largeFiles.length > 0} toggle={() => setLargeFiles([])}>
        The following files are too large to upload:
        <ul>
          {largeFiles.map((file) => (
            <li key={file}>{file}</li>
          ))}
        </ul>
      </Alert>
      <FilePickerBtn
        allowedFileTypes={ALLOWED_FILE_TYPES}
        maxFiles={MAX_FILES}
        maxSize={MAX_FILE_SIZE}
        name={FILE_INPUT_NAME}
        setMaxFilesError={setMaxFilesError}
        setLargeFiles={setLargeFiles}
      />
      <FileList files={field.value} onRemoveFile={handleRemoveFile} />
    </>
  );
};

const UploadMoreBtn = ({ onReset }: { onReset: () => void }) => {
  const { resetForm } = useFormikContext();

  return (
    <Button
      color="primary"
      type="reset"
      onClick={() => {
        resetForm();

        onReset();
      }}
    >
      Upload more files
    </Button>
  );
};

export const UploadButton = ({ className, selectedFolder }: { className?: string; selectedFolder: Folder }) => {
  const { customerId = '' } = useParams();
  const queryClient = useQueryClient();
  const { isExternal } = useAppContext();

  const [isOpen, setIsOpen] = useState(false);
  const [isFileTooLarge, setIsFileTooLarge] = useState(false);

  const {
    mutate: handleUploadFile,
    isLoading,
    isSuccess,
    isError,
    reset,
  } = useMutation<UploadResponse[], unknown, UploadRequest>(
    async ({ files, path, customerId }) => {
      const requests = files.map((file) =>
        isExternal
          ? externalUploadFile(file.name, path, file, customerId)
          : uploadFile(file.name, path, file, customerId)
      );
      const responses = await Promise.all(requests);
      return responses;
    },
    {
      onSuccess: (resp, request) => {
        // Fetch the files again after upload
        if (isExternal) {
          queryClient.invalidateQueries(['nodes', request.path]);
          queryClient.invalidateQueries(['files', request.path]);
        }
        // Fetch the files again after upload
        queryClient.invalidateQueries(['nodes', request.path, request.customerId]);
        queryClient.invalidateQueries(['files', request.path, request.customerId]);
      },
    }
  );

  /**
   * Handles form submit and uploads the list of files
   */
  const handleSubmit = async (values: FormValues) => {
    // Check for errors
    if (values.files.length > 0 && !isFileTooLarge) {
      handleUploadFile({ files: values.files, path: selectedFolder.data, customerId });
    }
  };

  /**
   * Toggle modal isOpen
   */
  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  // Reset mutation state
  useEffect(() => {
    if (!isOpen) {
      reset();
      setIsFileTooLarge(false);
    }
  }, [isOpen, reset]);

  return (
    <>
      <Button color="success" className={className} onClick={toggle}>
        <Icon name="upload-cloud" />
        Upload
      </Button>
      <Modal isOpen={isOpen} toggle={toggle} labelledBy="upload-file-header" role="dialog" aria-modal>
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <ModalHeader id="upload-file-header" toggle={toggle} tag="div">
            <h2 className="h5">Upload a file</h2>
          </ModalHeader>
          <BlockUi blocking={isLoading} tag={ModalBody}>
            {isSuccess ? (
              <div className="text-center">
                <SuccessMessage />
                <UploadMoreBtn onReset={reset} />
              </div>
            ) : (
              <>
                <Alert isOpen={isError} color="danger">
                  There was an error uploading the file
                </Alert>
                <Alert isOpen={isFileTooLarge} color="danger">
                  This file is too large and is unable to be uploaded. The current limit is 150MB.
                </Alert>
                <p>Select up to {MAX_FILES} files for upload. The maximum allowed file size is 150MB per file.</p>
                <FileUpload />
              </>
            )}
          </BlockUi>
        </Form>
      </Modal>
    </>
  );
};
