/* eslint-disable unicorn/no-array-callback-reference */
import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { UseQueryOptions } from '@tanstack/react-query';
import moment from 'moment';
import type { Moment } from 'moment';

import { externalFetchAllNodes, fetchAllNodes, getMailbox } from '@/api';
import type { Folder, FileNode, MFTNodes } from '@/types';

const STALE_TIME = 10_000; // 10 seconds

export const useFetchNodes = (
  name: string,
  customerId: string,
  options?: UseQueryOptions<MFTNodes, unknown, MFTNodes, string[]>
) => {
  return useQuery(['nodes', name, customerId], () => fetchAllNodes(name, customerId), {
    enabled: !!name,
    retry: false,
    staleTime: STALE_TIME, // 10 seconds
    ...options,
  });
};

const isFolder = (node: FileNode | Folder): node is Folder => !node.leaf;

export const useFolders = (
  name: string,
  customerId: string,
  options?: UseQueryOptions<Folder[], unknown, Folder[], string[]>
) => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ['folders', name, customerId],
    queryFn: async () => {
      const nodes = await queryClient.fetchQuery(['nodes', name, customerId], () => fetchAllNodes(name, customerId));
      return nodes.filter(isFolder).sort((a, b) => a.label.localeCompare(b.label));
    },
    enabled: !!name,
    retry: false,
    staleTime: STALE_TIME,
    ...options,
  });
};

export const useAdminFolders = (
  target: string,
  nodeName: string,
  options?: UseQueryOptions<Folder[], unknown, Folder[], string[]>
) => {
  const queryClient = useQueryClient();

  return useQuery(
    ['admin-folders', target, nodeName],
    async () => {
      const resp = await queryClient.fetchQuery(['admin-nodes', target, nodeName], () =>
        getMailbox({ target, nodeName })
      );
      return resp.nodes.filter(isFolder).sort((a, b) => {
        const comparison = a.label.localeCompare(b.label);
        return moment(a.label, true).isValid() && moment(b.label, true).isValid() ? comparison * -1 : comparison;
      });
    },
    {
      enabled: !!target,
      retry: false,
      staleTime: STALE_TIME,
      ...options,
    }
  );
};

export const useExternalFolders = (
  nodeName: string,
  options?: UseQueryOptions<Folder[], unknown, Folder[], string[]>
) => {
  const queryClient = useQueryClient();

  return useQuery(
    ['folders', nodeName],
    async () => {
      const nodes = await queryClient.fetchQuery(['nodes', nodeName], () => externalFetchAllNodes(nodeName));
      return nodes.filter(isFolder).sort((a, b) => a.label.localeCompare(b.label));
    },
    {
      enabled: !!nodeName,
      retry: false,
      staleTime: STALE_TIME,
      ...options,
    }
  );
};

const isFile = (node: FileNode | Folder): node is FileNode => node.leaf;

const compareDates = (dateA: Moment, dateB: Moment) => {
  if (dateA.isSame(dateB)) return 0;
  return moment(dateA).isBefore(dateB) ? 1 : -1;
};

export const useFiles = (
  name: string,
  customerId: string,
  options?: UseQueryOptions<FileNode[], unknown, FileNode[], string[]>
) => {
  const queryClient = useQueryClient();

  return useQuery(
    ['files', name, customerId],
    async () => {
      const nodes = await queryClient.fetchQuery(['nodes', name, customerId], () => fetchAllNodes(name, customerId));
      return nodes.filter(isFile).sort((a, b) => compareDates(moment(a.last_modified_on), moment(b.last_modified_on)));
    },
    {
      enabled: !!name,
      retry: false,
      staleTime: STALE_TIME,
      ...options,
    }
  );
};

export const useAdminFiles = (
  target: string,
  nodeName: string,
  options?: UseQueryOptions<FileNode[], unknown, FileNode[], string[]>
) => {
  const queryClient = useQueryClient();

  return useQuery(
    ['admin-files', target, nodeName],
    async () => {
      const resp = await queryClient.fetchQuery(['admin-nodes', target, nodeName], () =>
        getMailbox({ target, nodeName })
      );
      return resp.nodes
        .filter(isFile)
        .sort((a, b) => compareDates(moment(a.last_modified_on), moment(b.last_modified_on)));
    },
    {
      enabled: !!target,
      retry: false,
      staleTime: STALE_TIME,
      ...options,
    }
  );
};

export const useExternalFiles = (
  nodeName: string,
  options?: UseQueryOptions<FileNode[], unknown, FileNode[], string[]>
) => {
  const queryClient = useQueryClient();

  return useQuery(
    ['files', nodeName],
    async () => {
      const nodes = await queryClient.fetchQuery(['nodes', nodeName], () => externalFetchAllNodes(nodeName));
      return nodes.filter(isFile).sort((a, b) => compareDates(moment(a.last_modified_on), moment(b.last_modified_on)));
    },
    {
      enabled: !!nodeName,
      retry: false,
      staleTime: STALE_TIME,
      ...options,
    }
  );
};
