import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import './index.scss';

// if (process.env.NODE_ENV === 'development') {
//   // eslint-disable-next-line @typescript-eslint/no-var-requires
//   const { worker } = require('../config/browser');
//   worker.start();
// }
const container = document.getElementById('root');

const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);
