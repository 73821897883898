import React from 'react';
import Icon from '@availity/icon';

import type { Folder } from '@/types';

import { UploadButton } from './UploadButton';

export const FileViewerHeader = ({
  label,
  rightContent = null,
  selectedFolder,
  showUploadBtn = false,
}: {
  label: string;
  rightContent?: React.ReactNode | null;
  selectedFolder: Folder;
  showUploadBtn?: boolean;
}) => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-end">
        <div className="d-flex justify-content-between align-items-center">
          <Icon name="folder-open-empty" className="mr-2" size="lg" />
          <h4 className="m-0">{label}</h4>
        </div>
        {showUploadBtn ? (
          <div>
            <UploadButton selectedFolder={selectedFolder} />
          </div>
        ) : null}
        {rightContent}
      </div>
      <hr />
    </>
  );
};
