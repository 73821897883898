import AvSpacesApi, { avUserPermissionsApi } from '@availity/api-axios';

const SEND_RECEIVE_EDI_ID = 'send_receive_edi_files';
const PILOT_MFT_PERMISSION = '7965';

type SpaceResponse = {
  metadata: any;
}

export default class SendReceiveEdiSpacesApi extends AvSpacesApi {
  constructor(config) {
    super({
      path: 'api/sdk/platform',
      name: 'spaces',
      ...config,
    });
  }

  async permissionCheck(permission: string): Promise<boolean> {
    try {
      const permissions = await avUserPermissionsApi.getPermissions(permission);
      return permissions && permissions.length > 0;
    } catch {
      return false;
    }
  }

  async getShouldForceSecureTransport() {
    const permissionFound = await this.permissionCheck(PILOT_MFT_PERMISSION);
    const sendReceiveEdiSpace = await this.get<SpaceResponse>(SEND_RECEIVE_EDI_ID);

    return sendReceiveEdiSpace.data.metadata.SHOULD_FORCE_SECURE_TRANSPORT === 'true' && !permissionFound;
  }
}
