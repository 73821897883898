import React, { useState, useMemo } from 'react';
import { HashRouter, Switch, Route, useHistory } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'react-block-ui/style.css';
import { Button } from 'reactstrap';
import { ErrorBoundary } from 'react-error-boundary';
import { ThemeProvider } from '@availity/element';

import { Layout, AdminLayout, ExternalLayout, LogoutChecker } from './components';
import { AdminDashboard, CreateMailbox, CreateUser, Search } from './AdminDashboard';
import { External } from './External';
import { Manager as FileManager } from './Manager';
import { OrgSelect } from './OrgSelect';
import { ForgotPassword, UpdatePassword } from './ForgotPassword';
import { IS_EXTERNAL, IS_LOCAL } from './constants';
import { appContext, useAppContext } from './context';

// Create react-query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

/**
 * Button used to change which view is displayed. Only available
 * with local development
 */
const ExternalToggleBtn = () => {
  const { isExternal, setIsExternal, setIsLoggedIn } = useAppContext();
  const history = useHistory();

  if (!IS_LOCAL) return null;

  return (
    <Button
      onClick={() => {
        setIsLoggedIn(false);
        setIsExternal((prev) => !prev);
        history.push('/');
      }}
      className="external-toggle"
    >
      Toggle {isExternal ? 'Internal' : 'External'} View
    </Button>
  );
};

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isPasswordExpired, setPasswordExpired] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isExternal, setIsExternal] = useState(IS_EXTERNAL);
  const [loggedOutModalIsOpen, setLoggedOutModalIsOpen] = useState(false);
  const [mailbox, setMbx] = useState({ id: '', entry: '', target: '' });
  const [username, setUsername] = useState('');
  const [organization, setOrganization] = useState(null);

  const contextValues = useMemo(() => ({
    isLoggedIn,
    setIsLoggedIn,
    isPasswordExpired,
    setPasswordExpired,
    isLoggingOut,
    setIsLoggingOut,
    loggedOutModalIsOpen,
    setLoggedOutModalIsOpen,
    isExternal,
    setIsExternal,
    mailbox,
    setMbx,
    username,
    setUsername,
    organization,
    setOrganization,
  }), [
    isLoggedIn,
    setIsLoggedIn,
    isPasswordExpired,
    setPasswordExpired,
    isLoggingOut,
    setIsLoggingOut,
    loggedOutModalIsOpen,
    setLoggedOutModalIsOpen,
    isExternal,
    setIsExternal,
    mailbox,
    setMbx,
    username,
    setUsername,
    organization,
    setOrganization,
  ])
  return (
    <ThemeProvider theme="legacyBS">
      <ErrorBoundary fallback={<div>Something went wrong</div>}>
        <appContext.Provider
          value={contextValues}
        >
          <QueryClientProvider client={queryClient}>
            <LogoutChecker />
            <HashRouter>
              {isExternal ? (
                <Switch>
                  <Route path="/">
                    <ExternalLayout>
                      <External />
                    </ExternalLayout>
                  </Route>

                  <Route path="/forgot-password">
                    <ExternalLayout>
                      <ForgotPassword />
                    </ExternalLayout>
                  </Route>

                  <Route path="/update-password">
                    <ExternalLayout>
                      <UpdatePassword />
                    </ExternalLayout>
                  </Route>
                  {IS_LOCAL ? (
                    <>
                      <Route path="/admin-dashboard">
                        <AdminLayout>
                          <AdminDashboard />
                        </AdminLayout>
                      </Route>

                      <Route path="/admin-dashboard/create-mbx">
                        <AdminLayout>
                          <CreateMailbox />
                        </AdminLayout>
                      </Route>

                      <Route path="/admin-dashboard/create-user">
                        <AdminLayout>
                          <CreateUser />
                        </AdminLayout>
                      </Route>

                      <Route path="/admin-dashboard/search">
                        <AdminLayout>
                          <Search />
                        </AdminLayout>
                      </Route>
                    </>
                  ) : null}
                </Switch>
              ) : (
                <Switch>
                  <Route path="/org-select">
                    <Layout>
                      <OrgSelect />
                    </Layout>
                  </Route>

                  <Route path="/file-manager/:customerId">
                    <Layout>
                      <FileManager />
                    </Layout>
                  </Route>

                  <Route path="/create-user">
                    <AdminLayout>
                      <CreateUser />
                    </AdminLayout>
                  </Route>

                  <Route exact path="/admin-dashboard">
                    <AdminLayout>
                      <AdminDashboard />
                    </AdminLayout>
                  </Route>

                  <Route path="/admin-dashboard/create-mbx">
                    <AdminLayout>
                      <CreateMailbox />
                    </AdminLayout>
                  </Route>

                  <Route path="/admin-dashboard/create-user">
                    <AdminLayout>
                      <CreateUser />
                    </AdminLayout>
                  </Route>

                  <Route path="/admin-dashboard/search">
                    <AdminLayout>
                      <Search />
                    </AdminLayout>
                  </Route>
                </Switch>
              )}
              <ExternalToggleBtn />
            </HashRouter>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </appContext.Provider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
