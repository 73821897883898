import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import { Alert } from 'reactstrap';

import { FolderItem } from '@/components';
import { useFolders } from '@/hooks';
import type { Folder } from '@/types';

const ROOT = '/';

export const FileSystemTree = ({
  folder,
  selectedFolder,
  setSelectedFolder,
  setHasFolderError,
}: {
  folder: Folder;
  selectedFolder: Folder;
  setSelectedFolder: (folder: Folder) => void;
  setHasFolderError: (hasFolder: boolean) => void;
}) => {
  const { customerId = '' } = useParams();
  const isRoot = folder.data === ROOT;
  const [isOpen, setIsOpen] = useState(isRoot);

  const {
    data: folders = [],
    isError,
    isFetched,
    isLoading,
  } = useFolders(folder.data, customerId, { enabled: !!folder.data && !!customerId && isOpen });

  useEffect(() => {
    setHasFolderError(isError);
  }, [isError, setHasFolderError]);

  const toggleIsOpen = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <BlockUi tag="ul" className={`list-unstyled${isRoot ? ' ml-0' : ' ml-3'}`} blocking={isLoading && isOpen}>
      <Alert color="warning" isOpen={isFetched && isRoot && folders.length === 0}>
        No folders available.
      </Alert>
      <FolderItem
        folder={folder}
        isActive={folder.key === selectedFolder.key}
        isOpen={isOpen}
        onClick={setSelectedFolder}
        toggleIsOpen={toggleIsOpen}
      >
        {folders.map((node) => (
          <FileSystemTree
            key={node.key}
            folder={node}
            selectedFolder={selectedFolder}
            setSelectedFolder={setSelectedFolder}
            setHasFolderError={setHasFolderError}
          />
        ))}
      </FolderItem>
    </BlockUi>
  );
};
