import React from 'react';
import { useParams } from 'react-router-dom';
import BlockUi from 'react-block-ui';

import { FileTable, FileViewerHeader } from '@/components';
import { useFiles } from '@/hooks';
import type { Folder } from '@/types';

export const FileViewer = ({ selectedFolder }: { selectedFolder: Folder }) => {
  const { customerId = '' } = useParams();

  const { data: files = [], isFetching } = useFiles(selectedFolder.key, customerId);

  if (!selectedFolder.key) return <p>Select a directory on the left to view the files.</p>;

  return (
    <BlockUi blocking={isFetching}>
      <FileViewerHeader
        label={
          files
            ? `${selectedFolder.label} (${files.length.toLocaleString()} file${files.length === 1 ? '' : 's'})`
            : selectedFolder.label
        }
        selectedFolder={selectedFolder}
        showUploadBtn={selectedFolder.key === '//SendFiles'}
      />
      {files.length > 0 ? (
        <FileTable files={files} selectedFolder={selectedFolder} />
      ) : (
        <p>This directory is currently empty. {selectedFolder.key === '//SendFiles' ? 'Use the Upload button above to add a file.' : ''}</p>
      )}
    </BlockUi>
  );
};
