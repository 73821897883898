import React, { useState, useEffect } from 'react';
import { useAppContext } from '@/context';
import { useMutation } from '@tanstack/react-query';
import { useIdle, useClickAway } from '@uidotdev/usehooks';
import { useInterval } from 'react-use';
import { useEffectAsync, useMount } from '@availity/hooks';
import { logout } from '@/api';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export const AboutToLogoutModal = ({
  aboutToLogoutModalIsOpen,
  setAboutToLogoutModalIsOpen,
  setAboutToLogoutModalHasBeenOpenForTenMinutes,
}) => {
  useMount(() => {
    setAboutToLogoutModalIsOpen(true);

    return () => {
      setAboutToLogoutModalIsOpen(false);
    };
  });

  const [minutesTilExpiration, setMinutesTilExpiration] = useState(10);

  useInterval(() => {
    setMinutesTilExpiration(minutesTilExpiration - 1);
  }, 60000);

  const closeModal = () => setAboutToLogoutModalIsOpen(!aboutToLogoutModalIsOpen);

  const ref = useClickAway(() => {
    setAboutToLogoutModalIsOpen(false);
  });

  useEffect(() => {
    if (minutesTilExpiration < 1) {
      setAboutToLogoutModalHasBeenOpenForTenMinutes(true);
    }
  }, [minutesTilExpiration, setAboutToLogoutModalHasBeenOpenForTenMinutes]);

  return (
    <Modal isOpen>
      <div ref={ref}>
        <ModalHeader>Are you still working?</ModalHeader>
        <ModalBody>
          To keep your info safe, your session will expire soon. To stay active, click the &apos;Close&apos; button or
          anywhere outside the modal in the next{' '}
          {minutesTilExpiration > 1 ? `${minutesTilExpiration} minutes` : 'minute'}
        </ModalBody>
        <ModalFooter>
          <Button onClick={closeModal} color="primary">
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export const LogoutChecker = () => {
  const idleForThirtyMinutes = useIdle(60000 * 30);
  const idleForTwentyMinutes = useIdle(60000 * 20);
  const [aboutToLogoutModalIsOpen, setAboutToLogoutModalIsOpen] = useState(false);
  const [aboutToLogoutModalHasBeenOpenForTenMinutes, setAboutToLogoutModalHasBeenOpenForTenMinutes] = useState(false);
  const { setIsLoggedIn, setLoggedOutModalIsOpen, setIsLoggingOut } = useAppContext();

  const { mutate: handleLogout, isLoading } = useMutation(logout, {
    onSettled: () => {
      setIsLoggedIn(false);
      setLoggedOutModalIsOpen(true);
      setIsLoggingOut(false);
    },
  });

  useEffect(() => {
    setIsLoggingOut(isLoading);
  }, [isLoading, setIsLoggingOut]);

  useEffectAsync(async () => {
    if (idleForThirtyMinutes || aboutToLogoutModalHasBeenOpenForTenMinutes) {
      handleLogout();
    }
  }, [idleForThirtyMinutes, aboutToLogoutModalHasBeenOpenForTenMinutes]);

  if ((idleForTwentyMinutes && !idleForThirtyMinutes) || aboutToLogoutModalIsOpen)
    return (
      <AboutToLogoutModal
        aboutToLogoutModalIsOpen={aboutToLogoutModalIsOpen}
        setAboutToLogoutModalIsOpen={setAboutToLogoutModalIsOpen}
        setAboutToLogoutModalHasBeenOpenForTenMinutes={setAboutToLogoutModalHasBeenOpenForTenMinutes}
      />
    );
  return null;
};
