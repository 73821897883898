import React, { useState } from 'react';
import { Controller, FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  Popper,
  RequiredKey,
  Stack,
  TextField,
  Typography,
} from '@availity/element';
import * as yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import BlockUi from 'react-block-ui';

import { createUser } from '@/api';
import { useAppContext } from '@/context';
import { CreateUserFormValues } from '@/types';
import { PUBLIC_KEY_REGEX } from '@/constants';

import { PublicKeys } from './Search/components/MuiPublicKeys';

const initialValues: CreateUserFormValues = {
  username: '',
  password: '',
  verifyPassword: '',
  showPassword: false,
  passwordExpired: false,
  mailbox: '',
  notes: '',
  email: '',
  phone: '',
  publicKeys: [],
};

// Password rules https://gitlab.com/Availity/teams/mft/modules/mft-api/-/blob/master/src/users/passwords/password.service.ts#L80
const isLowercase = (char: string) => char === char.toLowerCase() && char !== char.toUpperCase();
const isUppercase = (char: string) => char === char.toUpperCase() && char !== char.toLowerCase();
// eslint-disable-next-line prefer-regex-literals
const specialChars = new RegExp(/[!"#$%&'*+,/:;<=>?@[\\\]^`{|}~-]/);

const pwRules = [
  'Must be at least 14 characters',
  'Must have at least 1 lowercase character',
  'Must have at least 1 uppercase character',
  'Must have at least 1 special character',
  'Password cannot contain username',
  'Must match in both fields',
];

const schema = yup.object().shape({
  username: yup
    .string()
    .matches(/^[\W\w]+$/, 'Username has invalid characters.')
    .required('Username is required'),
  password: yup
    .string()
    .test('hasLowercase', 'Password must have at least 1 lowercase letter', (value) => {
      if (!value) return true;
      return [...value].some((val) => isLowercase(val));
    })
    .test('hasUppercase', 'Password must have at least 1 uppercase letter', (value) => {
      if (!value) return true;
      return [...value].some((val) => isUppercase(val));
    })
    .test('hasNumber', 'Password must have at least 1 number', (value) => {
      if (!value) return true;
      return [...value].some((val) => {
        return !Number.isNaN(Number(val));
      });
    })
    .test(
      'notContainsUsername',
      'Password must not contain the username',
      (value, { parent: { username } }) => !value || !username || !value.includes(username)
    )
    .matches(specialChars, 'Password must contain at least 1 special character')
    .min(14, 'Password must have at least 14 characters')
    .required('Password is required'),
  verifyPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords do not match!')
    .required('Please enter the password twice'),
  notes: yup.string().max(500, 'Notes must be 500 characters or less'),
  email: yup.string().email('Please enter a valid email address'),
  passwordExpired: yup.boolean(),
  phone: yup.string(),
  publicKeys: yup.array().of(
    yup.object().shape({
      value: yup.string().matches(PUBLIC_KEY_REGEX, 'Public key format is invalid'),
    })
  ),
});

const FieldGrid = ({ children }: { children: React.ReactNode }) => <Grid marginBottom="1.5rem">{children}</Grid>;

const FormFields = () => {
  const [popperAnchorEl, setPopperAnchorEl] = useState<HTMLInputElement | HTMLTextAreaElement | null>(null);

  const showPassword = useWatch<CreateUserFormValues>({ name: 'showPassword' });
  const {
    formState: { errors },
    register,
  } = useFormContext<CreateUserFormValues>();

  return (
    <Grid marginBottom="1.5rem">
      <FieldGrid>
        <TextField
          {...register('username')}
          label="Username"
          helperText={errors.username?.message as string}
          error={!!errors.username?.message}
          required
        />
      </FieldGrid>
      <FieldGrid>
        <TextField
          {...register('password')}
          label="Password"
          type={showPassword ? 'text' : 'password'}
          helperText={errors.password?.message as string}
          error={!!errors.password?.message}
          onFocus={(event) => {
            setPopperAnchorEl(event.currentTarget);
          }}
          onBlur={(event) => {
            setPopperAnchorEl(null);
            register('password').onBlur(event);
          }}
          required
        />
      </FieldGrid>
      <Popper open={Boolean(popperAnchorEl)} anchorEl={popperAnchorEl} placement="right">
        <Box maxWidth="300px" marginLeft="1rem">
          <Paper>
            <Box padding=".5rem 1rem" sx={{ backgroundColor: 'grey.100' }}>
              <Typography variant="h5">Password Requirements</Typography>
            </Box>
            <Box padding=".5rem 1rem">
              <ul>
                {pwRules.map((rule) => (
                  <li key={rule}>{rule}</li>
                ))}
              </ul>
            </Box>
          </Paper>
        </Box>
      </Popper>
      <FieldGrid>
        <TextField
          {...register('verifyPassword')}
          label="Confirm Password"
          helperText={errors.verifyPassword?.message as string}
          error={!!errors.verifyPassword?.message}
          type={showPassword ? 'text' : 'password'}
          required
        />
      </FieldGrid>
      <FieldGrid>
        <Grid container spacing={2}>
          <FormControlLabel
            control={
              <Controller
                name="showPassword"
                render={({ field }) => {
                  return (
                    <Checkbox
                      {...field}
                      checked={field.value ?? false}
                      onChange={(event) => {
                        field.onChange(event.target.checked);
                      }}
                    />
                  );
                }}
              />
            }
            label="Show Password"
          />
          <FormControlLabel
            control={
              <Controller
                name="passwordExpired"
                render={({ field }) => {
                  return (
                    <Checkbox
                      {...field}
                      {...register('passwordExpired')}
                      checked={field.value ?? false}
                      onChange={(event) => {
                        field.onChange(event.target.checked);
                      }}
                    />
                  );
                }}
              />
            }
            label="Force Change Password Upon Login"
          />
        </Grid>
      </FieldGrid>
      <FieldGrid>
        <TextField {...register('mailbox')} label="Mailbox" disabled />
      </FieldGrid>
      <FieldGrid>
        <TextField
          {...register('email')}
          helperText={errors.email?.message as string}
          error={!!errors.email?.message}
          label="Email Contact"
        />
      </FieldGrid>
      <FieldGrid>
        <TextField
          {...register('phone')}
          helperText={errors.phone?.message as string}
          error={!!errors.phone?.message}
          label="Phone Contact"
        />
      </FieldGrid>
      <FieldGrid>
        <TextField
          {...register('notes')}
          helperText={errors.notes?.message as string}
          error={!!errors.notes?.message}
          label="Notes"
          type="textarea"
          multiline
        />
      </FieldGrid>
      <FieldGrid>
        <PublicKeys />
      </FieldGrid>
    </Grid>
  );
};

export const CreateUser = () => {
  const { mailbox } = useAppContext();
  const { mutate: handleCreateUser, isLoading, isError, isSuccess, reset } = useMutation(createUser);

  const onSubmit = (values: CreateUserFormValues) => {
    const request = {
      username: values.username,
      password: values.password,
      email: values.email || undefined,
      phone: values.phone || undefined,
      notes: values.notes || undefined,
      publicKeys: values.publicKeys.map(({ value }) => value.trim()),
      mailboxes: values.mailbox || undefined,
      passwordExpired: values.passwordExpired || false,
    };
    handleCreateUser({ ...request, mailboxes: [mailbox] });
  };

  const formMethods = useForm<CreateUserFormValues>({
    defaultValues: { ...initialValues, mailbox: mailbox.target },
    resolver: yupResolver(schema),
  });

  return (
    <Container>
      <Card>
        <CardHeader title="Create User" />
        <BlockUi tag={CardContent} blocking={isLoading}>
          <Box maxWidth="700px" margin="auto">
            <FormProvider {...formMethods}>
              <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                {isError && <Alert severity="error">There was an error creating the user</Alert>}
                {isSuccess && <Alert severity="success">User created successfully!</Alert>}
                <Grid container direction="column" marginBottom={3}>
                  <h3>User Information</h3>
                  <RequiredKey />
                </Grid>
                <FormFields />
                <Stack direction="row" justifyContent="flex-end" spacing={1}>
                  <Button
                    type="reset"
                    color="secondary"
                    onClick={() => {
                      reset();
                      formMethods.reset();
                    }}
                  >
                    Clear
                  </Button>
                  <Button type="submit" color="primary">
                    Submit
                  </Button>
                </Stack>
              </form>
            </FormProvider>
          </Box>
        </BlockUi>
      </Card>
    </Container>
  );
};
