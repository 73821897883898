import React from 'react';
import { Row, Col, Card, Button } from 'reactstrap';
import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import BlockUi from 'react-block-ui';
import { Form, Field } from '@availity/form';
import * as yup from 'yup';

import { editMailbox, getMailbox } from '@/api';
import { USER } from '../../../constants';

const FormField = ({
  isEditMode,
  label,
  labelId,
  name,
  value,
}: {
  isEditMode: boolean;
  label: string;
  labelId: string;
  name: string;
  value: string;
}) => {
  return (
    <>
      <dt id={labelId}>{label}</dt>
      <dd>{isEditMode ? <Field name={name} aria-labelledby={labelId} /> : value}</dd>
    </>
  );
};

const schema = yup.object().shape({
  customerType: yup.string().required('Customer Type is required'),
});

export const MailboxInfo = ({
  enabled,
  isEditMode,
  target,
  setValuesCopy,
  setSearchType,
  submitFormCopy,
}: {
  enabled: boolean;
  isEditMode: boolean;
  target: string;
  setValuesCopy: (values: any) => void;
  setSearchType: (searchType: string) => void;
  submitFormCopy: () => Promise;
}) => {
  const { data, isLoading } = useQuery(['mailbox-config', target], () => getMailbox({ target }), {
    enabled: !!target && enabled,
    retry: 1,
  });

  const { mutate: handleUpdateMbx, isLoading: isUpdating } = useMutation(editMailbox);

  if (!target) return null;

  const mailbox = data?.mailbox;

  const searchForUser = async (event) => {
    const { value } = event.target;

    if (value) {
      setValuesCopy({ searchType: USER, username: value });
      setSearchType(USER);
      await submitFormCopy();
    }
  };

  return (
    <BlockUi
      tag={Row}
      blocking={isLoading || isUpdating}
      className="mt-3 justify-content-center"
      renderChildren={!isLoading}
    >
      {mailbox && !isLoading ? (
        <Col>
          <Form
            initialValues={mailbox}
            validationSchema={schema}
            onSubmit={(values) => {
              // Remove old values
              // const request = {};
              // for (const key of Object.keys(values)) {
              //   if (values[key] !== mailbox[key]) {
              //     request[key] = values[key];
              //   }
              // }
              handleUpdateMbx({
                target,
                updateMailboxInput: {
                  customerType: values.customerType,
                  sourceType: values.sourceType,
                },
              });
            }}
          >
            <Card className="border-0 p-2 bg-transparent">
              <Row>
                <Col>
                  <dt>Created</dt>
                  <dd>{mailbox.createdAt ? moment(Math.abs(+mailbox.createdAt)).format('MM/DD/YYYY h:mm a') : ''}</dd>
                </Col>
                <Col>
                  <dt>Last updated</dt>
                  <dd>{mailbox.updatedAt ? moment(Math.abs(+mailbox.updatedAt)).format('MM/DD/YYYY h:mm a') : ''}</dd>
                </Col>
                <Col>
                  <FormField
                    name="customerType"
                    label="Customer Type"
                    labelId="customer-type-label"
                    isEditMode={isEditMode}
                    value={mailbox.customerType}
                  />
                </Col>
                <Col>
                  <FormField
                    name="sourceType"
                    label="Source Type"
                    labelId="source-type-label"
                    isEditMode={isEditMode}
                    value={mailbox.sourceType}
                  />
                </Col>
              </Row>
              {isEditMode && (
                <Row>
                  <Col>
                    <Button type="submit" className="float-right" color="primary">
                      Update
                    </Button>
                  </Col>
                </Row>
              )}
              <Row>
                <Col className="mbx-user-list">
                  <dt>Users</dt>
                  <dl>
                    {mailbox.users && mailbox.users.length > 0
                      ? mailbox.users
                        .sort((a, b) => a.username.localeCompare(b.username))
                        .map((user) => (
                          <dd key={user.username}>
                            <Button onClick={searchForUser} value={user.username} color="link">
                              {user.username}
                            </Button>
                          </dd>
                        ))
                      : 'No users associated with this mailbox'}
                  </dl>
                </Col>
              </Row>
            </Card>
          </Form>
        </Col>
      ) : (
        <span style={{ fontStyle: 'italic' }} className="my-2">
          No information for this mailbox on file
        </span>
      )}
    </BlockUi>
  );
};
