import { createContext, useContext } from 'react';
import { UserMailbox, Organization } from './types';

type AppContext = {
  isLoggedIn: boolean;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  isPasswordExpired: boolean;
  setPasswordExpired: React.Dispatch<React.SetStateAction<boolean>>;
  isLoggingOut: boolean;
  setIsLoggingOut: React.Dispatch<React.SetStateAction<boolean>>;
  isExternal: boolean;
  setIsExternal: React.Dispatch<React.SetStateAction<boolean>>;
  loggedOutModalIsOpen: boolean;
  setLoggedOutModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mailbox: UserMailbox;
  setMbx: React.Dispatch<React.SetStateAction<UserMailbox>>;
  username: string;
  setUsername: React.Dispatch<React.SetStateAction<string>>;
  organization: Organization | null;
  setOrganization: React.Dispatch<React.SetStateAction<Organization>> | React.Dispatch<React.SetStateAction<null>>;
};

export const appContext = createContext<AppContext | null>(null);

export const useAppContext = () => {
  const ctx = useContext(appContext);
  if (!ctx) throw new Error('AppContext must be used inside a Provider');
  return ctx;
};
