import React from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Button, FormLabel, Grid, TextField, Typography } from '@availity/element';

import { CreateUserFormValues } from '@/types';
import { MAX_PUBLIC_KEYS } from '@/constants';

type PublicKeys = {
  value: string;
}[];

export function PublicKeys() {
  const {
    register,
    formState: { errors },
  } = useFormContext<CreateUserFormValues>();
  const { fields, append, remove } = useFieldArray<CreateUserFormValues>({ name: 'publicKeys' });
  const publicKeys: PublicKeys = useWatch({ name: 'publicKeys' });

  const addButtonIsDisabled = publicKeys.length >= MAX_PUBLIC_KEYS || (publicKeys.length > 0 && publicKeys.some((pk) => !pk.value));

  return (
    <>
      <FormLabel>Public Keys</FormLabel>
      {fields.length > 0 ? (
        fields.map((field, index) => {
          const errorMessage = errors.publicKeys?.[index]?.value?.message;

          return (
            <Grid container spacing={2} justifyContent="space-between" marginBottom="1.5rem" key={field.id}>
              <Grid sm={10}>
                <TextField
                  {...register(`publicKeys.${index}.value`)}
                  error={!!errorMessage}
                  helperText={errorMessage}
                  multiline
                />
              </Grid>
              <Grid sm="auto">
                <Button
                  color="tertiary"
                  onClick={() => {
                    remove(index);
                  }}
                >
                  Remove
                </Button>
              </Grid>
            </Grid>
          );
        })
      ) : (
        <Typography marginTop=".5rem">No Public keys associated with this user</Typography>
      )}
      <Button
        color="tertiary"
        disabled={addButtonIsDisabled}
        type="button"
        onClick={() => {
          append({ value: '' });
        }}
      >
        Add
      </Button>
    </>
  );
}
