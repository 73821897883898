import React from 'react';
import BlockUi from 'react-block-ui';

import { FileTable, FileViewerHeader } from '@/components';
import { useExternalFiles } from '@/hooks';
import type { Folder } from '@/types';

export const ExternalFileViewer = ({ selectedFolder }: { selectedFolder: Folder }) => {
  const { data: files = [], isLoading } = useExternalFiles(selectedFolder.key);

  if (!selectedFolder.key) return <p>Select a directory on the left to view the files.</p>;

  return (
    <BlockUi blocking={isLoading}>
      <FileViewerHeader
        label={
          files
            ? `${selectedFolder.label} (${files.length.toLocaleString()} file${files.length === 1 ? '' : 's'})`
            : selectedFolder.label
        }
        selectedFolder={selectedFolder}
        showUploadBtn
      />
      {files.length > 0 ? (
        <FileTable files={files} selectedFolder={selectedFolder} />
      ) : (
        <p>This directory is currently empty.</p>
      )}
    </BlockUi>
  );
};
