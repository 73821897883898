import React from 'react';
import { useFormikContext, FieldArray } from 'formik';
import { Row, Col, Button, Label } from 'reactstrap';
import { Field } from '@availity/form';

import { User } from '@/types';
import { MAX_PUBLIC_KEYS } from '@/constants';

export const PublicKeys = ({
  user,
  isEditMode,
  className,
  labelTag,
}: {
  user?: User;
  isEditMode: boolean;
  className: string;
  labelTag?: React.ElementType;
}) => {
  const { values } = useFormikContext<{ publicKeys: string[] }>();
  const LabelTag = labelTag || Label;

  const addButtonIsDisabled = values.publicKeys.length >= MAX_PUBLIC_KEYS || (values.publicKeys.length > 0 && values.publicKeys.some((pk) => !pk));
  return (
    <>
      <LabelTag className={className}>Public Keys</LabelTag>
      {isEditMode ? (
        <FieldArray
          name="publicKeys"
          render={(arrayHelpers) => (
            <>
              {values.publicKeys && values.publicKeys.length > 0 ? (
                values.publicKeys.map((pk, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Row key={i}>
                    <Col sm={10}>
                      <Field type="textarea" name={`publicKeys.${i}`} />
                    </Col>
                    <Col sm={2}>
                      <Button color="danger" type="button" onClick={() => arrayHelpers.remove(i)}>
                        Remove
                      </Button>
                    </Col>
                  </Row>
                ))
              ) : (
                <div className="mb-1">No public keys associated with this user</div>
              )}
              <Button
                disabled={addButtonIsDisabled}
                color="success"
                type="button"
                onClick={() => arrayHelpers.push('')}
              >
                Add
              </Button>
            </>
          )}
        />
      ) : user.publicKeys && user.publicKeys.length > 0 ? (
        user.publicKeys.map((pk) => <dd key={pk}>{pk}</dd>)
      ) : (
        <div className="my-2">No public keys associated with this user</div>
      )}
    </>
  );
};
