import React from 'react';
import { Button } from 'reactstrap';
import Icon from '@availity/icon';

const fileTypeIconMap: Record<string, string> = {
  png: 'file-image',
  jpg: 'file-image',
  jpeg: 'file-image',
  gif: 'file-image',
  ppt: 'file-powerpoint',
  pptx: 'file-powerpoint',
  xls: 'file-excel',
  xlsx: 'file-excel',
  doc: 'file-word',
  docx: 'file-word',
  txt: 'doc-alt',
  text: 'doc-alt',
  zip: 'file-archive',
  '7zip': 'file-archive',
  xml: 'file-code',
  html: 'file-code',
  pdf: 'file-pdf',
};

const FileRow = ({ onRemove, file }: { file: File & { id: string }; onRemove: (id: string) => void }) => {
  const remove = () => {
    onRemove(file.id);
  };

  // Look for ext in the map. Default to "doc"
  const ext = file.name.split('.').pop();
  const icon = ext ? fileTypeIconMap[ext.toLowerCase()] || 'doc' : 'doc';

  return (
    <tr>
      <td className="align-middle" style={{ width: '10%' }}>
        <Icon name={icon} title={`${ext} File Icon`}>
          <span className="sr-only">{ext} File Icon</span>
        </Icon>
      </td>
      <td className="align-middle" style={{ width: '35%' }}>
        <div className="text-truncate" title={file.name}>
          {file.name}
        </div>
      </td>
      <td className="align-middle" style={{ width: '10%' }}>
        <Button data-testid="remove-file-btn" color="link" className="text-danger px-0" onClick={remove}>
          <Icon name="trash-empty">
            <span className="sr-only">
              Remove
              {file.name}
            </span>
          </Icon>
        </Button>
      </td>
    </tr>
  );
};

export default FileRow;
