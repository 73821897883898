import { avLogMessagesApiV2 } from '@availity/api-axios';

export function logInfo(event: string, customerId: string, data?: Record<string, unknown>): null {
  try {
    avLogMessagesApiV2.info({
      app: 'send-receive-edi',
      event,
      customerId,
      ...data,
    });
    return null;
  } catch {
    return null;
  }
}

export function logError(event: string, customerId: string, data?: Record<string, unknown>): null {
  try {
    avLogMessagesApiV2.error({
      app: 'send-receive-edi',
      event,
      cid: customerId,
      ...data,
    });
    return null;
  } catch {
    return null;
  }
}
