import { useField } from 'formik';
import React, { useRef } from 'react';
import { Button } from 'reactstrap';

import FilePicker from './FilePicker';

const FilePickerBtn = ({
  allowedFileTypes,
  maxFiles,
  maxSize,
  name,
  setLargeFiles,
  setMaxFilesError,
}: {
  allowedFileTypes: string[];
  maxFiles: number;
  maxSize: number;
  name: string;
  setLargeFiles: (value: string[]) => void;
  setMaxFilesError: (value: boolean) => void;
}) => {
  const input = useRef<HTMLInputElement>(null);
  const [field] = useField(name);

  /**
   * Handler for the Select File(s) button
   */
  const handleSelectClick = () => {
    if (input.current) {
      input.current.click();
    }
  };

  /**
   * Handler for the Reset button
   */
  const handleResetClick = () => {
    setMaxFilesError(false);
    setLargeFiles([]);
  };

  const isSelectFilesDisabled = field.value.length >= maxFiles;
  const isSubmitDisabled = field.value.length === 0;

  return (
    <>
      <div className="d-none">
        <FilePicker
          name={name}
          allowedFileTypes={allowedFileTypes}
          innerRef={input}
          maxFiles={maxFiles}
          maxSize={maxSize}
          setMaxFilesError={setMaxFilesError}
          setLargeFiles={setLargeFiles}
        />
      </div>
      <Button
        type="button"
        color="secondary"
        className="mr-2"
        onClick={handleSelectClick}
        disabled={isSelectFilesDisabled}
        aria-disabled={isSelectFilesDisabled}
      >
        Select File(s)
      </Button>
      <Button type="reset" color="danger" onClick={handleResetClick}>
        Reset
      </Button>
      <Button
        type="submit"
        color="primary"
        className="float-right"
        disabled={isSubmitDisabled}
        aria-disabled={isSubmitDisabled}
      >
        Submit
      </Button>
    </>
  );
};

export default FilePickerBtn;
