import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Card, CardHeader, CardBody, Col, Row, Badge } from 'reactstrap';

import { useQueryOrganizations } from '@/hooks';
import type { Folder } from '@/types';
import { useAppContext } from '@/context';

import { FileSystemTree, FileViewer } from './components';

const ROOT = '/';
const initialFolder: Folder = { data: ROOT, key: ROOT, label: 'Home', leaf: false };

export const Manager = () => {
  const { organization } = useAppContext();
  const [selectedFolder, setSelectedFolder] = useState<Folder>(initialFolder);
  const [hasFolderError, setHasFolderError] = useState(false);

  const _customerId = organization?.customerId || window?.location?.hash?.split('/')?.pop();

  const { data: orgs = [] } = useQueryOrganizations(_customerId, { enabled: !!_customerId });

  const orgName = organization?.name || orgs.find((org) => org.customerId === _customerId)?.name;

  return (
    <>
      {/* TODO: make better error message */}
      <Alert isOpen={hasFolderError} color="danger">
        There was an error retrieving the available folders.
      </Alert>
      {orgName && (
        <Row>
          <Col className="d-flex flex-row mb-2">
            <div className="h5 mb-0">
              <Badge className="mr-2" color="primary">
                {orgName}
              </Badge>
            </div>
            <Link to="/org-select">Change Organization</Link>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs="3">
          <Card>
            <CardHeader>
              <h3 className="h5">Folders</h3>
            </CardHeader>
            <CardBody>
              <FileSystemTree
                folder={initialFolder}
                selectedFolder={selectedFolder}
                setSelectedFolder={setSelectedFolder}
                setHasFolderError={setHasFolderError}
              />
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <FileViewer selectedFolder={selectedFolder} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
