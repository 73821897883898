import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Alert, Container } from 'reactstrap';
import Authorize from '@availity/authorize';

import { INTERNAL_USER_PERMISSION, MFT_PERMISSION, OLD_MFT_PERMISSION } from '@/constants';
import { useAppContext } from '@/context';

import { Header, AdminHeader, ExternalHeader } from './Header';
import { Unauthorized } from './Unauthorized';

const styles = { maxWidth: '1600px' };
const queryOptions = { staleTime: 10 * 60 * 1000 };

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const isOnFilesRoute = useRouteMatch('/file-manager/:customerId');

  return (
    <Container fluid={isOnFilesRoute ? 'lg' : 'sm'} className="pb-5" style={isOnFilesRoute ? styles : undefined}>
      {
        isOnFilesRoute ? (
          <Alert color="success">
            Welcome to the new Send and Receive Files app. You&apos;ll see a refreshed look and feel, but the functionality remains the same.
          </Alert>
        ) : null
      }
      <Header />
      <Authorize
        permissions={[MFT_PERMISSION, OLD_MFT_PERMISSION]}
        unauthorized={<Unauthorized />}
        queryOptions={queryOptions}
        loader
      >
        {children}
      </Authorize>
    </Container>
  );
};

export const AdminLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Container fluid="lg" className="pb-5" style={styles}>
      <Container>
        <AdminHeader />
      </Container>
      <Authorize permissions={[INTERNAL_USER_PERMISSION]} unauthorized={<Unauthorized />} queryOptions={queryOptions}>
        {children}
      </Authorize>
    </Container>
  );
};

export const ExternalLayout = ({ children }: { children: React.ReactNode }) => {
  const { isLoggedIn } = useAppContext();

  return (
    <Container className={isLoggedIn ? 'pb-5' : 'py-5'} style={isLoggedIn ? styles : undefined}>
      {
        isLoggedIn ? (
          <Alert color="success">
            Welcome to the new Send and Receive Files app. You&apos;ll see a refreshed look and feel, but the functionality remains the same.
          </Alert>
        ) : null
      }
      <ExternalHeader />
      {children}
    </Container>
  );
};
