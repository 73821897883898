import React, { useState } from 'react';
import BlockUi from 'react-block-ui';
import { InputGroup, InputGroupAddon, InputGroupText, Spinner } from 'reactstrap';
import { Form, Input } from '@availity/form';
import Icon from '@availity/icon';
import Fuse from 'fuse.js';
import { useQuery } from '@tanstack/react-query';

import { FileTable, FileViewerHeader } from '@/components';
import { useAdminFiles } from '@/hooks';
import type { FileNode, Folder } from '@/types';

const ITEMS_PER_PAGE = 25;

const initialValues = { search: '' };

const SearchBox = ({ onChange, isSearching }: { onChange: (input: string) => void; isSearching: boolean }) => {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={() => {
        // noop
      }}
    >
      <InputGroup>
        {isSearching && <Spinner color="success" />}
        <Input
          name="search"
          autoComplete="off"
          autoCorrect="off"
          onChange={(event) => {
            setTimeout(() => {
              onChange(event.target.value);
            }, 500);
          }}
        />
        <InputGroupAddon addonType="append">
          <InputGroupText>
            <Icon name="search" />
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    </Form>
  );
};

const fuse = new Fuse([] as FileNode[], { threshold: 0.3, keys: ['name'] });

export const AdminFileViewer = ({ target, selectedFolder }: { target: string; selectedFolder: Folder }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const { data = [], isLoading } = useAdminFiles(target, selectedFolder.key);
  const { data: files = [], isLoading: isSearching } = useQuery(['searched-files', data, searchTerm], () => {
    fuse.setCollection(data);
    return searchTerm ? fuse.search({ name: searchTerm }).map(({ item }) => item) : data;
  });

  if (!selectedFolder.key) return <p>Select a directory on the left to view the files.</p>;

  return (
    <BlockUi blocking={isLoading}>
      <FileViewerHeader
        label={
          files
            ? `${selectedFolder.label} (${files.length.toLocaleString()} file${files.length === 1 ? '' : 's'})`
            : selectedFolder.label
        }
        rightContent={<SearchBox onChange={setSearchTerm} isSearching={isSearching} />}
        selectedFolder={selectedFolder}
      />
      {files.length > 0 ? (
        <FileTable
          isAdmin
          files={files}
          mailboxTarget={target}
          itemsPerPage={ITEMS_PER_PAGE}
          selectedFolder={selectedFolder}
        />
      ) : (
        <p>This directory is currently empty.</p>
      )}
    </BlockUi>
  );
};
