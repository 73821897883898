import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Card, Container, Col, Row } from 'reactstrap';
import AppIcon from '@availity/app-icon';
import AvLink from '@availity/link';
import Authorize from '@availity/authorize';
import {
  MFT_FILE_RESTORE_PERMISSON,
  MFT_VIEW_MAILBOXES_PERMISSON,
  MFT_SUPER_USER_PERMISSON,
  AVAILITY_CUSTOMER_ID,
} from '../constants';

const NavCard = ({
  children,
  className,
  route,
  href = '',
}: {
  children: React.ReactNode;
  className?: string;
  route?: string;
  href?: string;
}) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <Card
      body
      tabIndex={0}
      role="button"
      onClick={(event) => {
        if (route) {
          event.preventDefault();
          history.push(`${location.pathname}/${route}`);
        }
      }}
      onKeyDown={(event) => {
        if (route && event.key === 'Enter') {
          event.preventDefault();
          history.push(`${location.pathname}/${route}`);
        }
      }}
      className={className}
      tag={AvLink}
      href={href}
      target={href ? '_top' : ''}
    >
      {children}
    </Card>
  );
};

const navConfigs = [
  {
    route: 'search',
    icon: 'SCH',
    label: 'Search',
    permissions: [MFT_VIEW_MAILBOXES_PERMISSON],
  },
  {
    route: 'create-mbx',
    icon: 'MBX',
    label: 'Create Mailbox',
    permissions: [MFT_SUPER_USER_PERMISSON],
  },
  {
    href: '/web/wparty/file-restore-ui/#/',
    icon: 'FR',
    label: 'File Restore',
    permissions: [MFT_FILE_RESTORE_PERMISSON],
  }
];

export const AdminDashboard = () => {
  const allNavConfigPermissions = navConfigs.reduce<string[]>((accum, config) => {
    const configPermissions = config.permissions.filter((p) => !accum.includes(p));
    accum = [...accum, ...configPermissions];
    return accum;
  }, []);

  return (
    <Container>
      <Authorize
        permissions={allNavConfigPermissions}
        customerId={AVAILITY_CUSTOMER_ID}
        unauthorized={
          <div className="text-center" style={{ fontStyle: 'italic' }}>
            You are not authorized to view this content
          </div>
        }
      >
        <Row>
          {navConfigs.map(({ route, icon, label, href, permissions }) => {
            return (
              <Authorize permissions={permissions} unauthorized={null} key={route}>
                <Col xs="6" md="4" key={icon}>
                  <NavCard href={href} route={route} className="mb-3 text-decoration-none">
                    <div className="d-flex align-items-center">
                      <AppIcon color="green" alt={`${route}-icon`} className="mr-2">
                        {icon}
                      </AppIcon>
                      {label}
                    </div>
                  </NavCard>
                </Col>
              </Authorize>
            );
          })}
        </Row>
      </Authorize>
    </Container>
  );
};
