import React from 'react';
import { Table } from 'reactstrap';

import FileRow from './FileRow';

const tableStyles: React.CSSProperties = { tableLayout: 'fixed' };

const FileList = ({
  files,
  onRemoveFile,
}: {
  files: (File & { id: string })[];
  onRemoveFile: (id: string) => void;
}) => {
  if (files.length === 0) return null;

  return (
    <Table size="sm" hover style={tableStyles}>
      <caption className="sr-only">List of files uploaded</caption>
      <thead>
        <tr>
          <th style={{ width: '10%', border: 0 }} scope="col">
            <span className="sr-only">File Icon</span>
          </th>
          <th style={{ width: '80%', border: 0 }} scope="col">
            <span className="sr-only">File Name</span>
          </th>
          {/* <th style={{ width: '45%', border: 0 }} scope="col">
            <span className="sr-only">Upload Progress</span>
          </th> */}
          <th style={{ width: '10%', border: 0 }} scope="col">
            <span className="sr-only">File Actions</span>
          </th>
        </tr>
      </thead>
      <tbody className="w-100">
        {files.map((file) => (
          <FileRow key={file.id} file={file} onRemove={onRemoveFile} />
        ))}
      </tbody>
    </Table>
  );
};

export default FileList;
