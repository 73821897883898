import { avOrganizationsApi, avUserApi } from '@availity/api-axios';
import { useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';

import { MFT_PERMISSION, OLD_MFT_PERMISSION } from '@/constants';
import type { Organization, User } from '@/types';

async function fetchUser() {
  try {
    const resp = await avUserApi.me();
    return resp as User;
  } catch {
    throw new Error('Unable to fetch user');
  }
}

type OrgResponse = AxiosResponse<{ organizations: Organization[] }>;

async function fetchOrganizations(userId: string, customerId: string) {
  try {
    const resp: OrgResponse = await avOrganizationsApi.getOrganizations({
      params: { userId, permissionId: [MFT_PERMISSION, OLD_MFT_PERMISSION], dropdown: true, customerId },
    });

    return resp.data.organizations;
  } catch {
    throw new Error('Unable to fetch organizations');
  }
}

export function useQueryOrganizations(customerId?: string, options?: UseQueryOptions<Organization[], unknown, Organization[], string[]>) {
  const client = useQueryClient();

  return useQuery(
    ['organizations'],
    async () => {
      const user = await client.fetchQuery(['user'], fetchUser, { staleTime: 20 * 60 * 1000 });
      return fetchOrganizations(user.akaname, customerId);
    },
    {
      staleTime: 20 * 60 * 1000,
      ...options,
    }
  );
}
